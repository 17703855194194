body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background: rgba(189, 189, 189, 0.323) url("./pinkflowersRev4.svg") no-repeat
    left -100px top -121px !important; */
}

@media (max-width: 480px) {
  body {
    /* background: rgba(189, 189, 189, 0.323) url("./pinkflowersRev4.svg")
      no-repeat left -70px top 200px !important; */

    /* background: url("./pinkflowersRev4.svg") left -70px top 200px no-repeat,
      url("./bluePlant2.svg") right -80px top -10px no-repeat #bdbdbd52 !important; */
    background-color: #c2b8a052 !important;
  }

  .mainPhoto {
    width: 100vw;
    height: 300px;
    max-width: 480px;
  }
  .topPush {
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .blueSquare {
    background-color: rgba(11, 11, 110);
    padding: 10px;
    color: white;
    margin-left: 1px;
    font-size: 30px;
    width: 80px !important;
    text-align: center;
  }

  .pageHeaders {
    color: rgba(11, 11, 110);
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  body {
    /* background: rgba(189, 189, 189, 0.323) url("./pinkflowersRev4.svg")
      no-repeat left -100px top -121px !important; */

    background: url(/static/media/pinkflowersRev4.5f528b79.svg) left -250px top -100px no-repeat,
      url(/static/media/bluePlant2.759ac7fa.svg) right -250px top -150px no-repeat #c2b8a052 !important;
  }
  .mainPhoto {
    width: 100vw - 220px;
    height: 40vh;
    max-width: 1024px;
  }
  .topPush {
    margin-top: 40px;
    margin-bottom: 50px;
  }

  .blueSquare {
    background-color: rgba(11, 11, 110);
    padding: 20px;
    color: white;
    margin-left: 1px;
    font-size: 50px;
    width: 100px !important;
    text-align: center;
  }
}

@media (min-width: 1025px) {
  body {
    /* background: url("./pinkflowersRev4.svg") left -100px top -121px no-repeat,
      url("./pinkflowersRev4.svg") right -500px bottom -100vh no-repeat
        #bdbdbd52 !important; */

    background: url(/static/media/pinkflowersRev4.5f528b79.svg) left -90px top -100px no-repeat,
      url(/static/media/bluePlant2.759ac7fa.svg) right -80px top -150px no-repeat #c2b8a052 !important;
  }

  .mainPhoto {
    width: 100vw - 220px;
    height: 50vh;
    max-width: 1024px;
  }
  .topPush {
    margin-top: 40px;
    margin-bottom: 50px;
  }

  .blueSquare {
    background-color: rgba(11, 11, 110);
    padding: 20px;
    color: white;
    margin-left: 1px;
    font-size: 50px;
    width: 100px !important;
    text-align: center;
    font-weight: 900;
  }
}

.blueSquareSpecial {
  background-color: rgba(11, 11, 110);
  padding: 20px;
  color: white;
  margin-left: 1px;
  font-size: 50px;
  font-weight: 900;
}

.blueSquareRow {
  /* margin-left: 150px !important;
  margin-right: 150px !important; */
}

.mainContainer {
  max-width: 1024px;
}

.ringTop {
  margin-top: 20px;
}

.largeImageRow {
  margin-bottom: 50px;
}

.cursiveFont {
  font-family: "Parisienne", cursive;
}

.straightFont {
  font-family: "Zen Kaku Gothic Antique", sans-serif;
}

.counterPush {
  margin-top: 10px;
}

.counterSpace {
  margin-top: 50px;
}

.disclaimer {
  font-size: 10px;
  padding-bottom: 70px;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
  line-height: 60px; /* Vertically center the text there */
  background-color: #f5f5f5;
  text-align: center;
  font-size: 30px;
}

.bolding {
  font-weight: bold;
}

.hashTag {
  background: -webkit-linear-gradient(rgba(11, 11, 110), rgba(225, 170, 170));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn-link {
  color: rgba(102, 45, 45, 0.796) !important;
  font-size: 20px !important;
}

.pageHeaders {
  color: rgba(11, 11, 110);
}

.sideYearText {
  -webkit-writing-mode: vertical-rl;
          writing-mode: vertical-rl;
  -webkit-text-orientation: mixed;
          text-orientation: mixed;
  font-size: 40px;
  font-weight: bold;
  padding-top: 10px;
}

.workBreak {
  word-wrap: break-word;
}

.yearBlockPadding {
  margin-top: 50px;
}

.ourStoryPhotos {
  width: 100%;
  height: 100%;
  max-width: 480px;
}

.ourStoryPhotosMobile {
  width: 150%;
  height: 80%;
}

.dot {
  height: 45px;
  width: 45px;
  background-color: rgb(189, 126, 130);
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
}

.imageCard {
  padding: 5px;
}

.cardMainBlue {
  border: 1px solid grey !important;
  background-color: rgba(11, 11, 110) !important;
  color: white !important;
}
.cardMainPink {
  border: 1px solid grey !important;
  background-color: rgb(189, 126, 130) !important;
  color: white !important;
}

.largeFontButton {
  font-size: 25px !important;
}

.center {
  margin: auto;
  width: 60%;
  border: 1px solid rgb(38, 39, 38);
  border-radius: 25px;
  padding: 30px;
}

.centerMobile {
  margin: auto;
  width: 100%;
  border: 1px solid rgb(38, 39, 38);
  border-radius: 25px;
  padding: 30px;
}
.btn-primary {
  background-color: rgba(11, 11, 110) !important;
}

.btn-secondary {
  background-color: rgb(189, 126, 130) !important;
}

.centerSuccess {
  margin: auto;
  width: 60%;

  border-radius: 25px;
  padding: 30px;
}

.strongLocation {
  font-weight: 900;
  text-decoration: underline;
}

.topTimeline {
  /* border-left: 1px solid black;
  box-shadow: -5px 0px 5px 1px #aaa; */
  text-align: center;
}

.secondTimeline {
  padding-left: 10px;
}

.boldThedays {
  font-weight: 900;
}


